import * as  React from "react";
import {useEffect, useRef} from "react";
import {Wrapper} from "@googlemaps/react-wrapper";

const GoogleMap = () => {

    return (
        <Wrapper apiKey={process.env.GATSBY_MAPS_API_KEY}>
            <MyMapComponent center={{lat: 55.95007087254622, lng: -3.274249152634839}} zoom={10}
                            streetViewControl={false}/>
        </Wrapper>
    )
}

function MyMapComponent({
                            center,
                            zoom,
                            streetViewControl
                        }: {
    // @ts-ignore
    center: google.maps.LatLngLiteral;
    zoom: number;
    streetViewControl: boolean
}) {
    const ref = useRef();

    useEffect(() => {
        // @ts-ignore
        var map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            streetViewControl
        });
        // @ts-ignore
        new window.google.maps.KmlLayer('https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=1zT87Un8TT4MmRsKP1cFoNFtFjx10tmma&lid=MLSfabZmZ1U', {
            suppressInfoWindows: true,
            preserveViewport: false,
            map: map
        });

    });

    return <div ref={ref} id="map" className={'min-h-[500px]'}/>;
}

export default GoogleMap