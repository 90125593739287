import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Colour from "../types/colours";
import {ButtonPrimary} from "./button";
import {FaRegQuestionCircle} from "react-icons/fa";

export default function () {
    return (
        <>
            <div className={`relative overflow-hidden ${Colour.herograd}`}>
                <div className={"max-w-7xl mx-auto"}>
                    <div
                        className={`py-12 md:text-center md:py-32 lg:py-44 xl:py-52 relative z-10 lg:max-w-2xl lg:w-full`}>
                        <main
                            className={"mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"}>
                            <div className={"sm:text-center lg:text-left"}>
                                <div className={"text-xl tracking-tight font-bold text-gray-900 sm:text-2xl md:text-3xl lg:text-4xl"}>
                                    <span
                                        className={"block xl:inline"}>Serving Currie, Balerno, Baberton, Colinton and </span>{' '}
                                    <span
                                        className={"block text-red-900 xl:inline"}>more</span>
                                </div>

                                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <ButtonPrimary attrs={{href: "/contact"}}>Get in touch</ButtonPrimary>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className={'absolute bottom-0 right-0 p-4 z-20'}>
                        <FaRegQuestionCircle title={"Credit: Adam Wilson @fourcolourblack on Unsplash"}
                                             className={'text-white text-lg font-bold hover:cursor-pointer'}/>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:diagonal-clip">
                    <StaticImage
                        loading={"eager"}
                        draggable={false}
                        className={"h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"}
                        src={"../images/edinburgh-minimised.jpg"}
                        alt={""}
                        role={"presentation"}
                    />
                </div>
            </div>
        </>
    )
}