import * as React from "react"
import {Accent, Layout} from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import {ButtonPrimary} from "../components/button"
import Colour from "../types/colours"
import GoogleMap from "../components/GMap"
import {StaticImage} from "gatsby-plugin-image";
import H from "../components/custhero";

class IndexPage extends React.Component {

    render() {
        const padding = 'py-24';
        return (
            <>
                <Layout>
                    <Seo title="Home"/>
                    <H/>
                    <Section padding={padding}>
                        <div className="items-center md:flex md:space-x-14">
                            <div className="md:w-1/2">
                                <h1 className="text-2xl font-semibold text-gray-800 mb-4 w-fit">
                                    About us
                                    {Accent()}
                                </h1>
                                <p className="max-w-md text-gray-800">
                                    We are a window cleaning company that has served Edinburgh for over
                                    20 years. We deliver bespoke solutions to customer's home and
                                    business alike. Get in touch via phone, email or our contact form.
                                </p>

                            </div>
                            <div className="mt-8 md:mt-0 md:w-1/2">
                                <div className="flex items-center justify-center">
                                    <StaticImage
                                        alt={"Van"}
                                        draggable={false}
                                        imgClassName={"object-cover object-center w-full rounded-md shadow-md"}
                                        src={"../images/van-branded.jpg"}
                                    />
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Section sectionColour={Colour.smokeWhite} padding={padding}>
                        <div className="items-center md:flex md:space-x-14">
                            <div className="flex items-center justify-center">
                                <div className="max-w-md">
                                    <StaticImage
                                        draggable={false}
                                        alt={"Van"}
                                        imgClassName={"object-cover object-center w-full rounded-md shadow-md"}
                                        src={"../images/van.jpeg"}
                                    />
                                </div>
                            </div>
                            <div className="mt-8 md:mt-0 md:w-1/2">

                                <h1 className="text-2xl font-semibold text-gray-800 mb-4 w-fit">
                                    Contacting us
                                    {Accent()}
                                </h1>

                                <table className="table-auto mb-14 text-left w-full">
                                    <tbody>
                                    <tr>
                                        <th>Email</th>
                                        <td><a href={'mailto:kwwc15@virginmedia.com'}>kwwc15@virginmedia.com</a></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile</th>
                                        <td><a href={'tel:07895916800'}>07895 916800</a></td>
                                    </tr>
                                    <tr>
                                        <th>Home</th>
                                        <td><a href={"tel:01314494379"}>0131 449 4379</a></td>
                                    </tr>
                                    <tr className={'align-top'}>
                                        <th>Address</th>
                                        <td>19/2 Bryce Road<br/>
                                            Currie<br/>
                                            Edinburgh <br/>
                                            EH14 5LP
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <ButtonPrimary attrs={{href: "/contact"}}>Get in touch</ButtonPrimary>
                            </div>
                        </div>
                    </Section>

                    <Section padding={padding}>
                        <h1 className="text-2xl font-semibold text-gray-800 mb-4 w-fit">
                            Where we work
                            {Accent()}</h1>
                        <p className={'mb-6 text-gray-800'}>The map below roughly indicates the areas we work in. It
                            gives a rough
                            guide as to the areas we cover and is not definitive. We may still be able to offer our
                            services to you if you fall outside the highlighted area.</p>
                        <GoogleMap/>


                    </Section>
                </Layout>
            </>
        )
    }
}


export default IndexPage
